<template>
<div class="reward-list">
  <div
    v-for="reward in project.rewards"
    :key="reward.id"
    ref="rewardList"
    class="reward-card-wrap"
  >
    <RewardCard
      :reward="reward"
      :projectId="project.id"
      :rewardsKey="rewardsKey"
      :status="RewardStatus.ADD"
      :projectActive="projectActive"
      :edit="edit"
      :currency="project.currency"
      @reward-add="$emit('reward-add')"
    />
  </div>
</div>
</template>

<script>
import RewardCard from '@/components/widget/RewardCard.vue';
import { RewardStatus, ProjectStatus } from '@/utils/constants';

export default {
  name: 'reward-list',
  components: {
    RewardCard,
  },
  props: {
    project: {
      type: Object,
      default: null,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    projectStatus: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      RewardStatus,
    };
  },
  computed: {
    projectActive() {
      return this.projectStatus === ProjectStatus.ACTIVE;
    },
    rewardsKey() {
      return this.project.crowdfunding ? 'rewards' : 'products';
    },
  },
};

</script>

<style lang="scss" scoped>
.reward-list {
  margin: 16px 0 24px;
  display: flex;
  flex-wrap: wrap;

  .reward-card-wrap {
    padding: 24px 48px 24px 0;
    display: flex;

    &:last-child {
      padding-right: 0;
    }
  }
}

@media (max-width: $mobile-width) {
  .reward-list .reward-card-wrap {
    width: 100%;
    padding: 8px 0;
  }
}
</style>
