<template>
<Modal
  class="share-modal"
  :cancelable="true"
  :title="$t('project.share.title')"
  :submitable="false"
  @cancel="$emit('close')"
>
  <template #content>
    <div class="share-icons">
      <a class="facebook" target="_blank" :href="facebookLink">
        <img :src="require('@/static/img/icons/share_facebook.png')">
      </a>
      <a class="twitter" target="_blank" :href="twitterLink">
        <img :src="require('@/static/img/icons/ic_twitter.png')">
      </a>
      <a class="linkedin" target="_blank" :href="linkedin">
        <img :src="require('@/static/img/icons/share_linkedin.png')">
      </a>
      <a class="mail" target="_blank" :href="mailLink">
        <img :src="require('@/static/img/icons/share_mail.png')">
      </a>
    </div>
    <h4>{{ $t('project.share.link') }}</h4>
    <div class="share-copy">
      <div class="share-copy-text-wrap">
        <div class="share-copy-text">
          {{ link }}
        </div>
      </div>
      <CopyButton :text="$t('copy_link')" :copyText="link" />
    </div>
  </template>
</Modal>
</template>

<script>
import CopyButton from '@/components/widget/CopyButton.vue';
import Modal from '@/components/widget/Modal.vue';
import { facebookShareUrl, twitterShareUrl, linkedinShareUrl } from '@/utils/config';

export default {
  name: 'share-modal',
  components: {
    CopyButton,
    Modal,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    facebookShareUrl,
    twitterShareUrl,
    linkedinShareUrl,
  }),
  computed: {
    link() {
      return `${window.location.origin}/project/${this.project.vanity_url}`;
    },
    caption() {
      return 'Join me in supporting our frontline healthcare workers!';
    },
    hash() {
      return 'PledgeToProtect';
    },
    facebookLink() {
      return `${facebookShareUrl}${this.link}&display=popup&hashtag=%23${this.hash}&quote=${this.caption}`;
    },
    twitterLink() {
      return `${twitterShareUrl}?text=${this.caption}&hashtags=${this.hash}&url=${this.link}`;
    },
    linkedin() {
      return `${linkedinShareUrl}&url=${this.link}&title=${this.caption}&summary=&source=`;
    },
    mailLink() {
      return `mailto:?&subject=${this.caption} #${this.hash}&body=${this.link}`;
    },
  },
};
</script>

<style lang="scss">
@import 'widgets';

.share-modal {
  .modal-header {
    text-align: center;
  }
  .share-icons {
    text-align: center;
    margin-top: 32px;
    a {
      outline: 0;
      &:not(:last-child) {
        margin-right: 32px;
      }
      &.facebook img {
        width: 10px;
        height: 20px;
      }
      &.twitter img {
        width: 24px;
        height: 18px;
      }
      &.linkedin img {
        width: 20px;
        height: 21px;
      }
      &.mail img {
        width: 24px;
        height: 18px;
      }
    }
  }
  h4 {
    margin-top: 40px;
  }
  .share-copy {
    display: flex;
    width: 100%;
    margin-top: 8px;
    .share-copy-text-wrap {
      padding: 0 16px;
      flex-grow: 1;
      box-sizing: border-box;
      border: 1px solid $border-light;
      border-right: none;
      display: flex;
      align-items: center;
      overflow: hidden;
    }
    .share-copy-text {
      @include p_small;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .copy-button {
      width: 120px;
      padding-right: 2px;
      flex-shrink: 0;
    }
  }
  @media (max-width: $mobile-width) {
    .share-copy {
      flex-wrap: wrap;
      .share-copy-text-wrap {
        border: 1px solid $border-light;
        height: 48px;
      }
      .copy-button {
        width: 100%;
      }
    }
  }
}
</style>
