<template>
<div class="project content" @click="clickOutside">
  <ProjectTrust
    v-if="checkedOut"
    :project="project"
    @set-tab="childSetTab"
  />
  <ProjectInfo
    v-if="project"
    :project="project"
    :projectActive="projectActive"
    @set-tab="childSetTab"
  />
  <div id="tab" />
  <ProjectTabBar
    :project="project"
    :activeTab="activeTab"
    :status="status"
    :showCart="showCart"
    :setTab="setTab"
    @set-tab="showCart = false"
    @hide-cart="showCart = false"
  />
  <keep-alive>
    <component
      :is="activeTab.component"
      v-if="project"
      :project="project"
      :status="status"
      @set-tab="childSetTab"
      @reward-add="onRewardAdd"
    />
  </keep-alive>
</div>
</template>

<script>
import ProjectInfo from '@/components/project/ProjectInfo';
import ProjectCampaign from '@/components/project/ProjectCampaign';
import ProjectFaq from '@/components/project/ProjectFAQ';
import ProjectUpdates from '@/components/project/ProjectUpdates';
import ProjectDiscussion from '@/components/project/ProjectDiscussion';
import ProjectTrust from '@/components/project/ProjectTrust';
import SubscribeModal from '@/components/project/SubscribeModal';
import ProjectTabBar from '@/components/project/ProjectTabBar';
import ShoppingCart from '@/components/widget/ShoppingCart';
import projectMixin from '@/mixins/Project';

import { GET_CART } from '@/store/actions';
import { ProjectStatus } from '@/utils/constants';
import { projectStatus } from '@/utils/objectUtils';
import * as api from '@/utils/api';

function parentHasClass(element, classTest) {
  const { className } = element;
  if(className && (String(className)).split(' ').indexOf(classTest) >= 0) {
    return true;
  }
  return element.parentNode && parentHasClass(element.parentNode, classTest);
}

const meta = (property, content) => (
  { vmid: property, property, content }
);

export default {
  name: 'project',
  components: {
    ShoppingCart,
    ProjectInfo,
    ProjectCampaign,
    ProjectFaq,
    ProjectDiscussion,
    ProjectUpdates,
    ProjectTrust,
    SubscribeModal,
    ProjectTabBar,
  },
  mixins: [projectMixin],
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    tab: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      activeTab: null,
      project: null,
      showCart: false,
      gaFetch: null,
    };
  },
  metaInfo() {
    const { project } = this;
    if(project) {
      let image = '';
      if(project.social_media_image && project.social_media_image.url) {
        image = project.social_media_image.url;
      } else if(project.images && project.images.length) {
        image = project.images[0].url;
      } else if(project.main_image && project.main_image.url) {
        image = project.main_image.url;
      }
      const url = `${window.location.origin}/project/${project.vanity_url}`;
      return {
        title: project.name,
        meta: [
          meta('og:title', project.name),
          meta('og:image', image),
          meta('og:url', url),
          meta('og:type', 'pledgecamp:project'),
          meta('twitter:image', image),
          meta('twitter:site', '@pledgecamp'),
          meta('twitter:card', 'summary_large_image'),
          meta('twitter:title', project.name),
          meta('twitter:description', project.blurb),
          meta('twitter:url', url),
        ],
      };
    }
    return {};
  },
  computed: {
    projectId() {
      return this.project ? this.project.id : null;
    },
    checkedOut() {
      return this.$store.getters.postCheckoutPledges(this.projectId).length > 0;
    },
    status() {
      return projectStatus(this.project);
    },
    projectActive() {
      return this.status === ProjectStatus.ACTIVE;
    },
    authenticated() {
      return this.$store.getters.isAuthenticated;
    },
    googleId() {
      return this.project ? this.project.google_analytics_id : null;
    },
  },
  methods: {
    clickOutside(e) {
      const { className } = e.target;
      if(this.showCart) {
        if(className === 'img-toggle-cart' || !parentHasClass(e.target, 'btn-toggle-cart')) {
          this.showCart = false;
        }
      } else if(className && className === 'img-toggle-cart') {
        this.showCart = true;
      }
    },
    onRewardAdd() {
      // Add small timeout to deal with clickOutside trigger
      setTimeout(() => {
        this.showCart = true;
      }, 150);
    },
  },
  async mounted() {
    this.project = await api.getProjectDetail(this.id);
    if(this.authenticated) {
      this.$log.debug('User is authenticated, trying to fetch cart');
      this.$store.dispatch(GET_CART, this.project.id);
    }
    const { googleId } = this;
    if(googleId) {
      this.gaFetch = document.createElement('script');
      this.gaFetch.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${googleId}`);
      document.head.appendChild(this.gaFetch);
      window.dataLayer = window.dataLayer || [];
      /* eslint-disable */
      window.projectGtag = function() {
        window.dataLayer.push(arguments);
      };
      /* eslint-enable */
      window.projectGtag('js', new Date());
      window.projectGtag('config', googleId);
    }
  },
  beforeDestroy() {
    if(this.gaFetch) {
      document.head.removeChild(this.gaFetch);
      window.projectGtag = null;
      this.gaFetch = null;
    }
  },
};
</script>
