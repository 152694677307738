<template>
<Modal
  :title="$t('project.trust.vote_modal.title')"
  :submitText="submitText"
  @submit="submitVote"
  @cancel="$emit('close-modal')"
>
  <template #content>
    <div class="vote-content">
      <p class="center-text p_small">
        {{ $t('project.trust.vote_modal.content') }}
      </p>
    </div>
    <div>
      <div class="refund-container">
        <div class="refund-text">
          {{ $t('project.trust.vote_modal.refund') }}
        </div>
        <div class="refund-amount-container">
          <div v-if="rateLoaded" class="refund-amount-pledge">
            <Spinner :size="24" />
          </div>
          <div v-else class="refund-amount-pledge">
            {{ $tc('project.trust.vote_modal.plg', insurancePlg) }}
          </div>
          <div class="refund-amount-dollar">
            {{ insuranceUsd }}
          </div>
        </div>
      </div>
      <ErrorMessage
        v-if="rateError"
        :errorMessage="rateError"
      />
      <div class="refund-info">
        <Info />
        <p class="p_info">
          {{ $t('project.trust.vote_modal.info') }}
        </p>
      </div>
    </div>
  </template>
</Modal>
</template>

<script>
import Modal from '@/components/widget/Modal.vue';
import ErrorMessage from '@/components/widget/ErrorMessage.vue';
import Info from '@/components/svg/Info.vue';
import Spinner from '@/components/widget/Spinner';
import { milestoneVote } from '@/utils/api';
import { firstErrorKey } from '@/utils/apiError';
import { priceDisplay } from '@/utils/objectUtils';
import { EXCHANGE_RATE_REQUEST } from '@/store/actions';

export default {
  name: 'project-trust-modal',
  components: {
    Modal,
    ErrorMessage,
    Info,
    Spinner,
  },
  props: {
    project: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      submitAnimate: false,
      voteError: null,
    };
  },
  computed: {
    insuranceUsd() {
      let value = this.$store.getters.totalCheckedoutValue(this.project.id);
      value = (value * this.project.reserve_percent) / 100;
      return priceDisplay(value, this.project.currency);
    },
    insurancePlg() {
      if(!this.plgToUsd) {
        return '?';
      }
      const usd = this.$store.getters.totalCheckedoutValue(this.project.id) / 100;
      const insurance = usd / this.plgToUsd;
      return Math.round((insurance + Number.EPSILON) * 100) / 100;
    },
    pledgeVote() {
      return this.$store.getters.milestoneVote(this.project.id).vote;
    },
    submitText() {
      if(this.pledgeVote) {
        return this.$t('project.trust.vote_modal.change_vote');
      }
      return this.$t('project.trust.vote_modal.vote_refund');
    },
    plgToUsd() {
      return this.$store.getters.plgToUsd;
    },
    rateError() {
      const error = this.$store.getters.rateError;
      console.log(error);
      if(error) {
        return this.$t(firstErrorKey(error));
      }
      return null;
    },
    rateLoaded() {
      return this.$store.getters.rateLoaded;
    },
  },
  methods: {
    async submitVote() {
      this.submitAnimate = true;
      try {
        await milestoneVote(this.project.id, true);
      } catch(e) {
        this.voteError = firstErrorKey(e.errors);
      }
    },
  },
  created() {
    this.$store.dispatch(EXCHANGE_RATE_REQUEST);
  },
};
</script>

<style lang="scss" scoped>
@import 'widgets';

.vote-content {
  margin-bottom: 32px;
}

.refund-container {
  @include h5;
  display: flex;
  align-items: center;
  border: 1px solid $border-light;
  padding: 16px;

  .refund-amount-container {
    display: flex;
    margin-left: auto;

    .refund-amount-pledge {
      margin-right: 24px;
      color: $grey;
    }
  }
}

.refund-info {
  display: flex;
  margin: 12px 0 30px 0;
  color: $grey;

  svg {
    margin-right: 8px;
    width: 16px;
    height: 16px;
  }
}
</style>
