<template>
<div class="comments">
  <div class="title">
    <span>{{ title }}</span>
    <div class="sort-options">
      <span
        v-for="(s, index) in $t('comments.sort_options')"
        :key="index"
        :class="{'option': true, 'selected': sortBy.option === s.option}"
        @click="updateSort(s)"
      >
        {{ s.label | uppercase }}
      </span>
    </div>
  </div>
  <div v-if="commentPermission" class="reply-box-wrap">
    <ReplyBox
      :parentId="project.comment_root_id"
      :onSubmit="addComment"
      :commentPlaceholder="$t('comments.writeComment')"
    />
  </div>
  <div v-else class="tip">
    {{ $t('comments.commentTip.desc') }} <span @click="pledgeClicked">{{ $t('comments.commentTip.link') }}</span>{{ $t('dot') }}
  </div>
  <Comment
    v-for="comment in comments"
    :key="comment.id"
    :sort="sortBy.option"
    :project="project"
    :creator="creator"
    :initialComment="comment"
    :commentPermission="commentPermission"
  />
</div>
</template>

<script>
import { addProjectComment, getCommentReplies } from '@/utils/api';

import Comment from '@/components/widget/Comment.vue';
import ReplyBox from '@/components/widget/ReplyBox.vue';

export default {
  name: 'comments',
  components: {
    Comment,
    ReplyBox,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    project: {
      type: Object,
      default: null,
    },
    commentPermission: Boolean,
  },
  data() {
    return {
      comments: [],
      sortBy: this.$t('comments.sort_options')[0],
    };
  },
  computed: {
    creator() {
      if(this.project && this.project.creator) {
        return this.project.creator;
      }
      return undefined;
    },
  },
  methods: {
    pledgeClicked() {
      this.$emit('go-to-pledge');
    },
    async getComments() {
      if(this.project && this.project.comment_root_id) {
        try {
          const { data: { comments } } = await getCommentReplies({
            commentId: this.project.comment_root_id,
            sort: this.sortBy.option,
          });
          this.comments = comments;
        } catch(e) {
          console.log(e);
        }
      }
    },
    addComment(reply, parentId) {
      if(this.project && this.project.id) {
        const { id: projectId } = this.project;

        addProjectComment({ projectId, parentId, text: reply })
          .then((res) => {
            const { comment } = res.data;
            this.comments.unshift(comment);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    async updateSort(sort) {
      if(this.sortBy.option !== sort.option) {
        this.sortBy = sort;
        await this.getComments();
      }
    },
  },
  async created() {
    await this.getComments();
  },
};
</script>

<style lang="scss">
@import 'general';

.comments {
  font-family: $font-normal;
  color: $main-black;

  .title {
    @include h3;
    margin-bottom: 18px;
    display: flex;
    justify-content: space-between;

    .sort-options {
      font-size: 12px;
      color: $grey;

      .option {
        padding: 0 10px;
        border-right: 1px solid $grey-light;
        cursor: pointer;

        &:first-of-type {
          padding-left: 0;
        }

        &:last-of-type {
          padding-right: 0;
          border-right: none;
        }

        &.selected {
          color: $main-black;
        }
      }
    }
  }

  .tip {
    border: 1px solid $grey-light;
    line-height: 24px;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    margin-bottom: 30px;
    color: $grey;

    span {
      color: $main-black;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .count {
    line-height: 21px;
    font-size: 18px;
    padding-left: 8px;
  }

  > .comment {
    margin-top: 22px;
  }
}
</style>
