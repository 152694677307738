<template>
<div v-if="project" class="project-info">
  <div class="container project-info-wrap">
    <div class="title-wrap">
      <h1 class="title">
        {{ project.name }}
      </h1>
    </div>
    <div class="project-info-left">
      <div class="members">
        <router-link class="member" :to="{ name: 'user-public', params: { id: project.creator.id } }">
          <img :src="project.creator | avatar">
          <span class="subheading">{{ project.creator.name }}</span>
        </router-link>
      </div>
      <p class="p_large blurb">
        {{ project.blurb }}
      </p>
    </div>
    <div v-if="projectActive" class="project-info-right">
      <div class="funding">
        <span class="number">{{ totalPledged }}</span>
        <span class="units">
          {{ $tc('project.funding_goal', fundingGoal) }}
        </span>
      </div>
      <div class="left-days">
        <span class="number">{{ daysLeft }}</span>
        <span class="units">{{ $tc('project.days_left_text', daysLeft) }}</span>
      </div>
      <div class="backers">
        <span class="number">{{ project.backer_count }}</span>
        <span class="units">{{ $t('project.backers') | capitalize }}</span>
      </div>
    </div>
    <div v-if="projectActive" class="project-info-progress">
      <ProgressBar :percent="fundingPercent" :height="8" :showTooltip="true" />
    </div>
  </div>
  <div v-if="!projectActive" class="container project-info-statusbar">
    <div class="statusbar-wrap">
      <div class="statusbar-left">
        {{ statusText }}
      </div>
      <div class="status-items">
        <div class="status-item">
          <img
            class="status-img"
            :src="require('@/static/img/icons/ic_check_done.png')"
          >
          <div>
            {{ $tc('project.funded', fundingPercent) }}
          </div>
        </div>
        <div class="status-item">
          <img
            class="status-img"
            :src="require('@/static/img/icons/ic_funds_raised.png')"
          >
          <div>
            {{ $tc('project.raised', raised) }}
          </div>
        </div>
        <div class="status-item">
          <img
            class="status-img backers"
            :src="require('@/static/img/icons/ic_backers_blue.png')"
          >
          <div>
            {{ $tc('project.backer_count', project.backer_count) }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container project-info-images">
    <ImageCarousel
      :images="projectImages"
      :videoID="project.main_video"
      @video-click="toggleVideoModal"
    />
  </div>
  <VideoModal
    :show="showVideoModal"
    :onClose="toggleVideoModal"
    :videoID="project.main_video"
  />
</div>
</template>

<script>
import moment from 'moment';
import ProgressBar from '@/components/widget/ProgressBar';
import ImageCarousel from '@/components/widget/ImageCarousel';
import VideoModal from '@/components/widget/VideoModal';
import { projectFundingPercent, priceDisplay } from '@/utils/objectUtils';

export default {
  name: 'project-info',
  components: {
    ProgressBar,
    ImageCarousel,
    VideoModal,
  },
  props: {
    project: {
      type: Object,
      default: null,
    },
    projectActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showVideoModal: false,
    };
  },
  computed: {
    fundingPercent() {
      return projectFundingPercent(this.project);
    },
    daysLeft() {
      if(this.project.end_time) {
        const dayleft = this.project.end_time.diff(moment(), 'days');
        return dayleft < 0 ? 0 : dayleft;
      }
      return '';
    },
    raised() {
      return this.$options.filters.from_cents(this.project.total_pledged);
    },
    statusText() {
      const reachedGoal = this.project.total_pledged >= this.project.funding_goal;
      if(!this.project.start_time || moment().isBefore(this.project.start_time)) {
        return this.$t('project.not_started');
      }
      return reachedGoal ? this.$t('project.success') : this.$t('project.failed');
    },
    projectImages() {
      return this.project.images;
    },
    totalPledged() {
      return priceDisplay(this.project.total_pledged, this.project.currency);
    },
    fundingGoal() {
      return priceDisplay(this.project.funding_goal, this.project.currency);
    },
  },
  methods: {
    pledgeClicked() {
      this.$emit('set-tab', {
        name: 'Campaign',
        hash: 'rewards',
      });
    },
    toggleVideoModal() {
      this.showVideoModal = !this.showVideoModal;
    },
  },
};
</script>

<style lang="scss">
@import 'widgets';

.project-info {
  .project-info-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 50px;
    max-width: 1110px;
    margin: auto;
  }

  .title-wrap {
    width: 100%;
    .title {
      max-width: 640px;
      margin: 0 0 15px 0;
    }
  }

  .project-info-left {
    width: 45%;

    .members {
      .member {
        display: flex;
        align-items: center;
        margin-right: 24px;
        margin-bottom: 10px;

        > img {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          margin-right: 9px;
        }
        > span {
          padding-top: 4px;
        }
      }
    }

    .blurb {
      margin: 30px 0 0 0;
    }
  }

  .project-info-right {
    margin-top: 40px;
    width: 45%;
    display: flex;
    justify-content: space-between;

    > div {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;

      .number {
        @include h1;
        color: $main-blue;
      }

      .units {
        @include h4;
      }
    }
  }

  .project-info-progress {
    width: 100%;
    margin-top: 40px;
  }

  .project-info-statusbar {
    @include h4;
    width: 100%;
    margin-top: 40px;
    border-top: 1px solid $border-light;
    border-bottom: 1px solid $border-light;
    padding: 26px 0 16px;
    .statusbar-wrap {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      max-width: 1110px;
      margin: auto;
      padding: 0 20px;
      .statusbar-left {
        display: flex;
        align-items: center;
        margin-right: 48px;
      }
      .status-items {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-left: auto;
        .status-item {
          margin-bottom: 5px;
          .status-img {
            height: 20px;
            width: 20px;
            margin: 0 14px 0 24px;
            &.backers {
              width: 26px;
            }
          }
          > div {
            display: inline-block;
            vertical-align: top;
          }
        }
      }
    }
  }

  .project-info-images {
    margin: 40px auto 100px;
    overflow: hidden;
  }

  @media (max-width: $tablet-width-large) {
    .project-info-wrap {
      padding-top: 20px;
      padding-bottom: 60px;
    }
    .project-info-left, .project-info-right {
      width: 100%;
      max-width: 520px;
    }
    .project-info-progress {
      margin-top: 50px;
    }
    .project-info-images {
      margin-bottom: 40px;
    }
  }

  // Hack for aligning wrapped flexbox
  @media (max-width: 948px) {
    .project-info-statusbar .statusbar-wrap .status-items {
      max-width: 365px;
      width: 365px;
      flex-shrink: 0;
    }
    .project-info-statusbar .statusbar-wrap .statusbar-left {
      margin-right: 0;
    }
  }

  @media (max-width: $tablet-width) {
    .project-info-wrap {
      padding-bottom: 35px;
    }
    .title {
      @include h2;
    }
    .project-info-left {
      .blurb {
        @include p_small;
        margin-top: 20px;
      }
    }
    .project-info-progress {
      margin-top: 10px;

      .progress-tooltip {
        display: none;
      }
    }
    .project-info-images {
      margin-bottom: 50px;
    }
  }

  @media (max-width: 520px) {
    .project-info-right {
      > div {
        .number {
          @include h3;
          color: $main-blue;
        }
        .units {
          @include subheading;
        }
      }
    }
    .project-info-statusbar {
      .statusbar-wrap {
        flex-wrap: wrap;
        .status-items {
          display: block;
          margin-left: 0;
          margin-top: 24px;
          max-width: 250px;
          .status-item {
            .status-img {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}
</style>
