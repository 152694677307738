<template>
<div class="container trust">
  <div class="trust-wrap">
    <div
      v-for="(pledge, ind) in postCheckoutPledges"
      :key="ind"
      class="trust-container trust-amount"
    >
      <div class="trust-image">
        <img :src="require('@/static/img/icons/ic_backers.png')">
      </div>
      <div class="reward-container">
        <p>
          {{ $t('project.trust.backed', [pledgedAmount(pledge), pledgedTime(pledge)]) }}
        </p>
        <button @click="showPledgeModal(pledge)">
          {{ $t('project.trust.pledge_cta') }}
        </button>
      </div>
    </div>
    <div
      v-if="showVote"
      class="trust-container"
      :class="[ pledgeVote ? 'trust-vote-true' : 'trust-vote-false']"
    >
      <img :src="require('@/static/img/icons/ic_protection.png')">
      <div class="vote-container">
        <div class="trust-vote-text">
          <p v-if="pledgeVote">
            {{ $t('project.trust.vote_positive') }}
          </p>
          <p v-else>
            {{ $t('project.trust.vote_negative') }}
            <span
              class="trust-vote-info"
              @mouseover="isToolTip = true"
              @mouseleave="isToolTip = false"
            >
              <span class="tooltip">
                <Info />
                <div v-show="isToolTip" class="tooltip-text">
                  {{ $t('project.trust.tooltip') }}
                </div>
              </span>
            </span>
          </p>
        </div>
        <button @click="showVoteModal = true">
          {{ $t('project.trust.vote_cta') }}
        </button>
      </div>
    </div>
  </div>
  <ProjectRewardModal
    v-if="showRewardModal"
    :projectActive="projectActive"
    :project="project"
    :pledge="activePledge"
    @add-more-rewards="addMoreRewards"
    @close-modal="hidePledgeModal"
  />
  <ProjectVoteModal
    v-if="showVoteModal"
    :project="project"
    @close-modal="showVoteModal = false"
  />
</div>
</template>

<script>
import moment from 'moment';
import ProjectVoteModal from '@/components/project/ProjectVoteModal';
import ProjectRewardModal from '@/components/project/ProjectRewardModal';
import Info from '@/components/svg/Info';
import { priceDisplay } from '@/utils/objectUtils';

export default {
  name: 'project-trust',
  components: {
    ProjectRewardModal,
    ProjectVoteModal,
    Info,
  },
  props: {
    project: {
      type: Object,
      default: () => {},
    },
    setTab: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isToolTip: false,
      showVoteModal: false,
      showRewardModal: false,
      activePledge: null,
    };
  },
  computed: {
    pledgeVote() {
      if(this.project) {
        return this.$store.getters.milestoneVote(this.project.id).vote;
      }
      return false;
    },
    postCheckoutPledges() {
      if(this.project) {
        return this.$store.getters.postCheckoutPledges(this.project.id);
      }
      return [];
    },
    showVote() {
      if(this.project) {
        const {
          end_time: end,
          funding_goal: goal,
          total_pledged: pledged,
        } = this.project;
        // Only show vote after the project is over, if it succeeded
        return moment().isAfter(end) && (pledged >= goal);
      }
      return false;
    },
    projectActive() {
      return moment().isAfter(this.project.start_time) && moment().isBefore(this.project.end_time);
    },
  },
  methods: {
    showPledgeModal(pledge) {
      this.activePledge = pledge;
      this.showRewardModal = true;
    },
    hidePledgeModal() {
      this.showRewardModal = false;
      this.activePledge = null;
    },
    pledgedTime(pledge) {
      return moment.utc(pledge.updated).local().format('DD MMM YYYY');
    },
    pledgedAmount(pledge) {
      return priceDisplay(pledge.total, this.project.currency);
    },
    addMoreRewards() {
      this.hidePledgeModal();
      this.$emit('set-tab', { name: 'project', hash: '#rewards' });
    },
  },
};
</script>

<style lang="scss">
@import 'general';
@import 'widgets';

.container.trust {
  overflow: unset;
  p {
    margin: 0;
  }
}

.trust-wrap {
  border: 1px solid $blue-med;
  margin-bottom: 32px;
  .vote-container, .reward-container {
    display: flex;
    width: 100%;
  }
  .trust-container {
    padding: 24px 32px;
    display: flex;
    align-items: center;
    &:not(:first-child) {
      border-top: 1px solid $grey-light;
    }
    img {
      height: 24px;
      width: 24px;
      margin-right: 32px;
    }
    p {
      @include h4;
    }
    button {
      @include link;
      text-transform: uppercase;
      margin-left: auto;
    }
    &.trust-vote-true {
      background-color: rgba(236, 74, 41, 0.1);
    }
    &.trust-vote-false .trust-vote-text p {
      color: $grey;
    }
  }

  .trust-vote-info {
    margin-left: 8px;
  }
  .tooltip {
    position: relative;
    .tooltip-text {
      position: absolute;
      z-index: 100;
      width: 350px;
      top: 100%;
      left: 50%;
      margin: 8px 0 0 -175px;
      padding: 28px 24px 24px 24px;
      background-color: #fff;
      box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
      @include p_small;
      font-weight: 300;
      &::after {
        content: " ";
        position: absolute;
        bottom: 100%;
        left: 50%;
        margin-left: -8px;
        border-width: 8px;
        border-style: solid;
        border-color: transparent transparent #fff transparent;
      }
    }
  }
  @media (max-width: $mobile-width) {
    margin: 0 -20px;
    border-left: none;
    border-right: none;
    .vote-container, .reward-container {
      flex-direction: column;
      align-items: flex-start;
    }
    .trust-container {
      align-items: flex-start;
      button {
        margin-left: 0;
        margin-top: 8px;
      }
    }
    .trust-vote-text {
      align-items: flex-start;
    }
  }
}
</style>
