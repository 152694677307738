<template>
<div class="container">
  <div class="project-update">
    <div v-if="!updates" class="project-update-none">
      {{ $t('project.updates.no_updates') }}
    </div>
    <div v-else class="updates">
      <UpdateSidebar
        :class="{ sidebar: true, hide: showDetailView }"
        :updates="updates"
        :onSelect="id => selectUpdate(updateFromId(id))"
        :toggleDetailView="toggleDetailView"
        :selectedId="(selectedUpdate || {}).id"
      />
      <UpdateContent
        :class="{ content: true, hide: !showDetailView }"
        :update="selectedUpdate || {}"
        :error="selectedUpdateError"
        :project="project"
        :toggleDetailView="toggleDetailView"
      />
      <h4 v-if="projectUpdates.length === 0">
        {{ $t('project.updates.no_creator_updates') }}
      </h4>
    </div>
  </div>
</div>
</template>

<script>
import moment from 'moment';
import { getProjectUpdate } from '@/utils/api';
import { firstErrorKey } from '@/utils/apiError';
import UpdateSidebar from '@/components/project/UpdateSidebar.vue';
import UpdateContent from '@/components/project/UpdateContent.vue';

export default {
  name: 'project-updates',
  components: {
    UpdateContent,
    UpdateSidebar,
  },
  props: {
    project: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selectedUpdate: null,
      selectedUpdateError: null,
      showDetailView: false,
    };
  },
  computed: {
    projectUpdates() {
      if(!this.project) {
        return [];
      }
      const updates = (this.project.updates || []).map(this.updateDisplay);
      updates.sort((a, b) => a.date - b.date);
      return updates;
    },
    updates() {
      if(!this.project) {
        return [];
      }
      const updates = [...this.projectUpdates];

      if(this.funded) {
        updates.unshift(this.updateDisplay({
          date: this.project.end_time,
          name: 'Project Funded',
          id: -1,
        }));
      }
      if(this.launched && this.project.start_time) {
        updates.unshift(this.updateDisplay({
          date: this.project.start_time,
          name: 'Project Launched',
          id: -1,
        }));
      }
      return updates;
    },
    launched() {
      if(!this.project) {
        return {};
      }
      const now = moment();
      return now > this.project.start_time;
    },
    funded() {
      if(!this.project) {
        return {};
      }
      return this.project.total_pledged > this.project.funding_goal;
    },
  },
  methods: {
    updateDisplay(update) {
      return {
        ...update,
        date: update.date.format('DD MMM YYYY'),
      };
    },
    async selectUpdate(update) {
      this.selectedUpdate = update;
      this.selectedUpdateError = null;

      if(update && update.id) {
        try {
          const { data } = await getProjectUpdate({
            projectId: this.project.id,
            updateId: this.selectedUpdate.id,
          });
          this.selectedUpdate = {
            ...update,
            description: data.update.description,
          };
        } catch(e) {
          this.selectedUpdateError = this.$t(firstErrorKey(e.errors));
        }

        const params = { id: this.$route.params.id, updateId: update.id };
        const location = { name: 'project-update', params };
        if(this.$route.fullPath !== this.$router.resolve(location).href) {
          this.$router.replace(location);
        }
      }
    },
    updateFromId(id) {
      return this.updates.find(u => u.id === id);
    },
    toggleDetailView() {
      this.showDetailView = !this.showDetailView;
    },
  },
  mounted() {
    const routeUpdate = this.updateFromId(parseInt(this.$route.params.updateId));
    const defaultUpdate = this.projectUpdates[0];
    this.selectUpdate(routeUpdate || defaultUpdate);
  },
};
</script>

<style lang="scss">
@import 'general';

.project-update {
  padding: 70px 70px;
  min-height: 400px;

  .project-update-none {
    font-size: 2em;
    margin-top: 48px;
  }
  .updates {
    display: flex;

    .sidebar {
      flex-basis: 40%;
      display: block;
    }
    .content {
      display: block;
    }
  }
  @media (max-width: $tablet-width) {
    padding: 70px 0;
  }

  @media (max-width: $mobile-width) {
    .updates {
      padding-top: 25px;
      .content.hide {
        display: none;
      }
      .sidebar {
        flex-basis: 100%;
        &.hide {
          display: none;
        }
      }
    }
  }
}

</style>
