<template>
<div class="update-content">
  <div class="header">
    <div class="mobile-close" @click="toggleDetailView">
      <img :src="require('@/static/img/icons/ic_close.png')">
    </div>
    <h1>{{ update.name }}</h1>
    <div class="header-details">
      <Avatar :user="author" :showName="true" />
      <span class="header-date">{{ updateDate }}</span>
    </div>
  </div>
  <div class="content-body">
    <div v-if="error" class="update-content-error">
      {{ error }}
    </div>
    <Markdown :content="update.description" />
  </div>
  <!-- <div class="comments">
    <Comments :project="project" />
  </div> -->
</div>
</template>

<script>
import moment from 'moment';

import Avatar from '@/components/widget/Avatar.vue';
import Markdown from '@/components/widget/Markdown.vue';
// import Comments from '@/components/widget/Comments.vue';
// TODO: adjust comments to handle updates as well, each update has own comments

export default {
  name: 'update-content',
  components: {
    Avatar,
    Markdown,
    // Comments,
  },
  props: {
    update: {
      type: Object,
      required: true,
    },
    error: {
      type: String,
      default: null,
    },
    project: {
      type: Object,
      required: true,
    },
    toggleDetailView: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    author() {
      if(!this.project) {
        return {};
      }
      return this.project.creator;
    },
    imageUrl() {
      const { image } = this.author;
      return (image || {}).url;
    },
    updateDate() {
      return moment(this.update.date).format('DD MMM YYYY');
    },
  },
};
</script>

<style lang="scss">
@import 'general';

.update-content {
  .mobile-close {
    display: none;
    margin-bottom: 24px;
    text-align: right;
    img {
      width: 24px;
      height: 24px;
    }
  }
  flex-basis: 60%;
  padding-left: 60px;
  .header-details {
    display: flex;
    align-items: center;
    @include subheading;
    height: 25px;
    margin-top: 20px;

    .avatar {
      height: 25px;
      .avatar-name {
        @include subheading;
      }
      margin-right: 16px;
    }
    .header-date {
      padding-top: 4px;
    }
  }
  .content-body {
    @include p_small;
    font-weight: 300;
    margin-top: 42px;
    .update-content-error {
      font-weight: 600;
      font-size: 18px;
    }
  }
  @media (max-width: $tablet-width) {
    .header-details {
      flex-wrap: wrap;
    }
  }
  @media (max-width: $mobile-width) {
    flex-basis: 100%;
    padding-left: 0;
    .header {
      .mobile-close {
        display: block;
      }
      h1 {
        @include h3;
      }
    }
  }
}
</style>
