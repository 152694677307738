<template>
<div class="p-image-carousel">
  <div class="images">
    <Carousel
      ref="carousel"
      :hideArrows="true"
      :slideCount="images.length + (videoID ? 1 : 0)"
      :slideWidth="900"
      @slide-change="handleSlideChange"
    >
      <div v-if="videoID" class="carousel-slide">
        <div
          class="image-wrap"
          :style="{ 'background-image': `url(https://img.youtube.com/vi/${videoID}/maxresdefault.jpg)` }"
        >
          <div
            class="video-overlay"
            @click="$emit('video-click')"
          >
            <div class="video-overlay-button">
              <Play :sizeMultiple="2.5" />
            </div>
          </div>
        </div>
      </div>
      <div v-for="(image, index) in images" :key="index" class="carousel-slide">
        <div
          class="image-wrap"
          :style="{ 'background-image': `url(${image.url})` }"
        />
      </div>
    </Carousel>
  </div>
  <div class="thumbnails">
    <div
      v-if="videoID"
      :class="{ 'thumbnail-wrap': true, 'active-slide': activeSlide === 0 }"
      :style="{ 'background-image': `url(https://img.youtube.com/vi/${videoID}/maxresdefault.jpg)` }"
      @click="handleSelectThumb(0)"
    />
    <div
      v-for="(image, index) in images"
      :key="index"
      :class="{
        'thumbnail-wrap': true,
        'active-slide': (index + videoAdjustment) === activeSlide,
      }"
      :style="{ 'background-image': `url(${image.url})` }"
      @click="handleSelectThumb(index + videoAdjustment)"
    />
  </div>
</div>
</template>

<script>
import Carousel from '@/components/widget/Carousel';
import Play from '@/components/svg/Play';

export default {
  name: 'image-carousel',
  components: {
    Carousel,
    Play,
  },
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    videoID: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      activeSlide: 0,
    };
  },
  computed: {
    videoAdjustment() {
      return this.videoID ? 1 : 0;
    },
  },
  methods: {
    handleSlideChange(activeSlide) {
      this.activeSlide = activeSlide;
    },
    handleSelectThumb(index) {
      this.$refs.carousel.goToSlide(index);
    },
  },
};
</script>

<style lang="scss">
@import 'widgets';

.p-image-carousel {
  .carousel-slide {
    width: 900px;
    min-width: 900px;
  }
  .images {
    position: relative;

    .image-wrap {
      height: 492px;
      position: relative;
      overflow: hidden;
      background-position: center;
      background-size: cover;

      .video-overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        .video-overlay-button {
          width: 90px;
          height: 90px;
          background: $main-black;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      > img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
    }
  }

  .thumbnails {
    display: flex;
    flex-direction: row;
    margin-top: 24px;

    .thumbnail-wrap {
      width: 115px;
      min-width: 115px;
      height: 65px;
      margin-right: 24px;
      opacity: 0.4;
      transition: all $speed2;
      cursor: pointer;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;

      &:hover {
        opacity: 0.6;
      }

      &.active-slide {
        opacity: 1;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
      }
    }
  }

  @media (max-width: $desktop-width-small) {
    .images {

      .image-wrap {
        margin-right: 22px;
        height: 376px;
      }

      .nav-buttons {
        display: none;
      }
    }
  }

  @media (max-width: $mobile-width) {
    .images {

      .image-wrap {
        margin-right: 16px;
        height: 176px;
      }
    }

    .thumbnails {
      display: none;
    }
  }
}
</style>
