<template>
<Modal
  class="project-reward-modal"
  :title="$t('project.trust.reward_modal.title')"
  :submitable="false"
  @cancel="$emit('close-modal')"
>
  <template #content>
    <div class="reward-header">
      <PledgeItem
        v-for="product in pledge.products"
        :key="product.id"
        :product="product"
        :changeable="!projectActive"
        :enableEdit="false"
        @increase-product="onIncrease"
        @decrease-product="onDecrease"
        @remove-product="onVariantRemove"
      />
      <div v-if="!projectActive" class="reward-header-button">
        <button @click="$emit('add-more-rewards')">
          {{ $t('project.trust.reward_modal.add_more') }}
        </button>
      </div>
    </div>
    <div class="reward-body">
      <PledgeBilling
        :needsShipping="pledge.needsShipping"
        :shippingCost="pledge.shippingTotal"
        :discount="pledge.discount"
        :cartSubtotal="pledge.subtotal"
        :hideTotal="true"
        :currency="project.currency"
      />
      <div class="reward-shipping-details-wrap">
        <div class="reward-total-price-wrap">
          <div class="reward-shipping-details">
            <h4 class="reward-total-label">
              {{ $t('total') }}
            </h4>
            <h2 class="reward-total-price">
              {{ pledgeTotal }}
            </h2>
          </div>
        </div>
        <div class="reward-shipping-info-wrap">
          <div class="reward-shipping-details">
            <div class="reward-label">
              {{ $t('project.trust.reward_modal.email') }}
            </div>
            <div class="reward-details">
              {{ pledge.email || '' }}
            </div>
          </div>
          <div class="reward-shipping-details">
            <div class="reward-label">
              {{ $t('project.trust.reward_modal.name') }}
            </div>
            <div class="reward-details">
              {{ pledge.name || '' }}
            </div>
          </div>
          <div v-if="pledge.needsShipping" class="reward-shipping-details">
            <div class="reward-label">
              {{ $t('shipping_address') }}
            </div>
            <div class="reward-details reward-details-address">
              {{ shipping.line1 }}<br>{{ shipping.line2 }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <button v-if="notCharged" class="btn-cancel-pledge" @click="cancelPromptOpen = true">
      {{ $t('project.trust.reward_modal.cancel') }}
    </button>
    <PromptModal
      v-if="cancelPromptOpen"
      :title="$t('modal.confirm_cancel')"
      :description="$t('project.trust.reward_modal.confirm_cancel_text')"
      :submitText="$t('confirm')"
      :confirmAnimating="confirmAnimating"
      @close-modal="cancelPromptOpen = false"
      @submit="cancelPledge"
    />
  </template>
</Modal>
</template>

<script>
import Modal from '@/components/widget/Modal';
import PledgeBilling from '@/components/pledge/PledgeBilling';
// import PledgeRewards from '@/components/project/PledgeRewards';
import PledgeItem from '@/components/pledge/PledgeItem';
import PromptModal from '@/components/widget/PromptModal';
import CartUpdate from '@/mixins/CartUpdate';
import { PLEDGE_CANCEL } from '@/store/actions';
import { PledgeStatus } from '@/utils/constants';
import { formatShipping, priceDisplay } from '@/utils/objectUtils';

export default {
  name: 'project-trust-modal',
  components: {
    Modal,
    PledgeBilling,
    PledgeItem,
    // PledgeRewards,
    PromptModal,
  },
  mixins: [CartUpdate],
  props: {
    pledge: {
      type: Object,
      default: null,
    },
    projectActive: Boolean,
  },
  data() {
    return {
      cancelPromptOpen: false,
      confirmAnimating: false,
    };
  },
  computed: {
    notCharged() {
      return this.pledge.status === PledgeStatus.CHECKED_OUT;
    },
    shipping() {
      return formatShipping(this.pledge.shippingAddress);
    },
    pledgeTotal() {
      return priceDisplay(this.pledge.total, this.project.currency);
    },
  },
  methods: {
    async cancelPledge() {
      const { pledge } = this;
      const projectId = this.project.id;
      this.confirmAnimating = true;
      await this.$store.dispatch(PLEDGE_CANCEL, { projectId, pledgeId: pledge.id });
      this.confirmAnimating = false;
      this.cancelPromptOpen = false;
      this.$emit('close-modal');
    },
  },
};
</script>

<style lang="scss">
@import 'widgets';

.project-reward-modal {
  .modal-inner {
    margin-top: 80px;
    max-width: 680px;
  }
  .modal-header {
    @include h3;
    text-align: left;
    margin-bottom: 16px;
  }

  .reward-label {
    color: grey;
  }

  .reward-header {
    .reward-image {
      display: flex;
      margin-right: 16px;
      img {
        width: 72px;
        height: 72px;
      }
    }
    .reward-price {
      margin-left: auto;
    }
    .reward-header-button {
      margin-top: 10px;
      button {
        @include link;
        text-transform: uppercase;
        font-size: 12px;
      }
    }
  }

  .reward-shipping-details-wrap {
    margin: 24px 0 0;
    display: flex;

    .reward-total-price-wrap {
      width: 200px;
      order: 2;
      align-items: center;
      margin-left: auto;
    }

    .reward-shipping-details {
      @include h5;
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
    }
    .reward-total-label {
      color: $grey;
      vertical-align: middle;
      line-height: 42px;
    }
    .reward-total-price {
      margin-left: auto;
    }
    .reward-label {
      width: 150px;
    }
    .reward-details {
      width: 200px;
    }
  }

  .reward-pricing-details {
    display: flex;
    flex-direction: column;
    padding: 16px 0 8px;
    margin-top: 24px;
    border-top: 1px solid $border-light;
    border-bottom: 1px solid $border-light;

    .reward-pricing-subtotal, .reward-pricing-shipping, .reward-pricing-discount {
      @include h5;
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
    }
    .reward-pricing-discount {
      margin-bottom: 4px;
      .reward-details {
        color: $main-red;
      }
    }
  }

  .btn-cancel-pledge {
    @include link;
    margin-top: 16px;
    text-transform: uppercase;
    font-size: 12px;
    color: $main-red;
  }

  @media (max-width: $tablet-width) {
    .modal-inner {
      padding: 0 20px 40px 20px;
      .modal-top {
        margin-right: -20px;
      }
    }
    .reward-shipping-details-wrap {
      display: block;
      .reward-total-price-wrap {
        width: unset;
        border-bottom: 1px solid $border-light;
      }
    }
    .btn-cancel-pledge {
      margin-top: 0;
    }
  }
}
</style>
