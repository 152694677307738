
export default {
  data() {
    return {
      dragging: false,
    };
  },
  methods: {
    tabFromName(name) {
      const tabs = this.$t('project.tabs');
      for(let i = 0; i < tabs.length; i += 1) {
        if(tabs[i].label.toLowerCase() === name.toLowerCase()) {
          return tabs[i];
        }
      }
      return tabs[0];
    },
    childSetTab(args) {
      const tab = this.tabFromName(args.name);
      this.setTab(tab, args.hash, args.query);
    },
    setTab(tab, hash = '', query = '') {
      const tabLabel = tab.label.toLowerCase();
      let { id } = this.$route.params;
      if(this.project) {
        id = this.project.vanity_url || this.project.id;
      }
      const params = { id, tab: tabLabel };
      // On first load, make sure we maintain the update ID
      if(this.$route.params.updateId) {
        params.updateId = this.$route.params.updateId;
      }
      if(tabLabel === 'project') {
        params.tab = null;
      }
      if(tab && this.activeTab) {
        if(tabLabel === this.activeTab.label.toLowerCase() && this.$route.hash === hash) {
          const el = document.getElementById(hash.slice(1));
          if(el) {
            window.scrollTo(0, el.offsetTop);
          }
          return;
        }
      }
      const name = (tab.component === 'project-updates') ? 'project-update' : 'project';
      const location = { name, params, hash, query };
      const nextLocation = this.$router.resolve(location);
      if(this.$route.fullPath !== nextLocation.href) {
        this.$router.replace(location);
      }
      this.activeTab = tab;
    },
    onWindowLoad() {
      const { hash } = this.$route;
      if(hash) {
        this.$nextTick(() => {
          setTimeout(() => {
            const el = document.getElementById(hash.slice(1));
            el.scrollIntoView();
          }, 500);
        });
      }
    },
  },
  created() {
    const { query, hash } = this.$route;
    this.setTab(this.tabFromName(this.tab), hash, query, true);
    window.addEventListener('load', this.onWindowLoad);
    this.onWindowLoad();
  },
};
