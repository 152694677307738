<template>
<Modal
  class="subscribe-modal"
  :cancelable="true"
  :title="subscribed ? $t('project.subscribe.title2') : $t('project.subscribe.title')"
  :submitText="$t(authenticated ? 'confirm' : 'login')"
  :animate="animate"
  @submit="subscribe"
  @cancel="$emit('close')"
>
  <template #content>
    <div class="subscribe-text">
      {{ text }}
    </div>
  </template>
</Modal>
</template>

<script>
import Modal from '@/components/widget/Modal.vue';
import { SET_LOGIN_REDIRECT, PROJECT_SUBSCRIPTION_UPDATE } from '@/store/actions';

export default {
  name: 'two-factor-modal',
  components: {
    Modal,
  },
  props: {
    projectId: {
      type: Number,
      required: true,
    },
    subscribed: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      animate: false,
    };
  },
  computed: {
    authenticated() {
      return this.$store.getters.isAuthenticated;
    },
    text() {
      if(!this.authenticated) {
        return this.$t('project.subscribe.login');
      }
      if(this.subscribed) {
        return this.$t('project.subscribe.text2');
      }
      return this.$t('project.subscribe.text');
    },
  },
  methods: {
    async subscribe() {
      if(this.authenticated) {
        this.animate = true;
        await this.$store.dispatch(PROJECT_SUBSCRIPTION_UPDATE, {
          projectId: this.projectId,
          subscribe: !this.subscribed,
        });
        this.animate = false;
        this.$emit('close');
      } else {
        this.$store.dispatch(SET_LOGIN_REDIRECT, this.$route.fullPath);
        this.$router.push({ name: 'login' });
      }
    },
  },
};
</script>

<style lang="scss">
@import 'general';

.subscribe-modal {
  .modal-header {
    text-align: center;
  }
  .subscribe-text {
    @include p_small;
    text-align: center;
    margin: 32px 0 40px;
  }
}
</style>
