<template>
<Sticky v-if="project">
  <TabBar
    id="project-tab"
    ref="projectTabBar"
    :tabs="$t('project.tabs')"
    :activeTab="activeTab"
    :onChangeTab="setTab"
    :updatesCount="updatesCount"
  >
    <template #menu>
      <div
        v-for="pTab in $t('project.tabs')"
        :key="pTab.label"
        :class="(pTab.label === activeTab.label) ? 'tab-bar-menu-item selected' : 'tab-bar-menu-item'"
        @click="() => { setTab(pTab); $refs.projectTabBar.toggleMenu(); }"
      >
        {{ pTab.label }}
        <span
          v-if="updatesCount && pTab.label === 'Updates'"
          class="tab-bar-menu-item-counter"
        >
          {{ updatesCount }}
        </span>
      </div>
      <div
        class="tab-bar-menu-item mobile-only"
        @click="() => { showShareModal = true; $refs.projectTabBar.toggleMenu(); }"
      >
        {{ $t('project.tab_items.share') }}
      </div>
      <div
        :class="`tab-bar-menu-item mobile-only ${subscribedText}`"
        @click="() => { showSubscribeModal = true; $refs.projectTabBar.toggleMenu(); }"
      >
        {{ subscribedText }}
      </div>
    </template>
    <template #right>
      <div class="tab-bar-menu-item desktop-only" @click="showShareModal = true">
        {{ $t('project.tab_items.share') }}
      </div>
      <div
        :class="`tab-bar-menu-item desktop-only ${subscribedText}`"
        @click="showSubscribeModal = true"
      >
        {{ subscribedText }}
      </div>
      <div v-if="projectActive" class="tab-bar-menu-item btn-toggle-cart">
        <img
          :src="require('@/static/img/icons/ic_cart.svg')"
          class="img-toggle-cart"
        >
        <span v-if="hasShoppingCart" class="has-cart" />
        <ShoppingCart
          v-if="showCart"
          :project="project"
          @hide-cart="$emit('hide-cart')"
          @add-rewards="addRewards"
          @checkout="checkout"
        />
      </div>
    </template>
  </TabBar>
  <SubscribeModal
    v-if="showSubscribeModal"
    :projectId="projectId"
    :subscribed="subscribed"
    @close="showSubscribeModal = false"
  />
  <ShareModal
    v-if="showShareModal"
    :project="project"
    @close="showShareModal = false"
  />
</Sticky>
</template>

<script>
import ShareModal from '@/components/project/ShareModal.vue';
import SubscribeModal from '@/components/project/SubscribeModal.vue';
import TabBar from '@/components/widget/TabBar.vue';
import Sticky from '@/components/widget/Sticky.vue';
import ShoppingCart from '@/components/widget/ShoppingCart.vue';

import { ProjectStatus } from '@/utils/constants';

export default {
  name: 'project-tab-bar',
  components: {
    TabBar,
    Sticky,
    ShoppingCart,
    ShareModal,
    SubscribeModal,
  },
  props: {
    project: {
      type: Object,
      default: null,
    },
    activeTab: {
      type: Object,
      default: () => {},
    },
    status: {
      type: String,
      default: null,
    },
    showCart: {
      type: Boolean,
      default: false,
    },
    setTab: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      showShareModal: false,
      showSubscribeModal: false,
    };
  },
  computed: {
    projectId() {
      return this.project ? this.project.id : null;
    },
    hasShoppingCart() {
      return this.$store.getters.hasShoppingCart(this.projectId);
    },
    projectActive() {
      return this.status === ProjectStatus.ACTIVE;
    },
    updatesCount() {
      if(!this.project || !this.project.updates) {
        return 0;
      }
      return this.project.updates.length;
    },
    subscribedText() {
      if(this.subscribed) {
        return this.$t('project.tab_items.subscribed');
      }
      return this.$t('project.tab_items.subscribe');
    },
    subscribed() {
      return this.$store.getters.projectSubscriptions.includes(this.projectId);
    },
  },
  methods: {
    checkout() {
      this.$router.push({
        name: 'checkout',
        params: { id: this.projectId },
      });
    },
    addRewards() {
      this.$emit('hide-cart');
      this.setTab(this.$t('project.tabs')[0], '#rewards');
    },
  },
};
</script>

<style lang="scss">
@import 'general';

.project {
  .tab-bar-wrap {
    border-top: 1px solid $border-light;
    border-bottom: 1px solid $border-light;
    background-color: $white;
  }
  .tab-bar {
    @include container;
    overflow: visible;
    .tab-bar-menu-item {
      @include h5;
      line-height: 85px;
      height: 85px;
      @media (max-width: $tablet-width) {
        line-height: 56px;
        height: 56px;
      }
      &.selected {
        border-bottom: 3px solid $main-blue;
      }
      &.Subscribed {
        color: $grey2;
      }
    }
    .tab-bar-active .tab-bar-menu-item,
    .tab-bar-menu-item.btn-toggle-cart {
      line-height: 85px;
      height: 85px;
    }
    .tab-bar-menu-item-counter {
      margin-left: 4px;
      color: $grey;
    }
    .tab-bar-right {
      .btn-toggle-cart {
        position: relative;
        img {
          margin-bottom: -5px;
        }
        .has-cart {
          position: absolute;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: $main-orange;
          top: 25px;
          right: 3px;
        }
      }
      .p-shopping-cart {
        position: absolute;
        right: 0;
        top: 85px;
        @media (max-width: $tablet-width) {
          right: -20px;
        }
      }
    }
  }
  .desktop-only {
    display: initial;
  }
  .mobile-only {
    display: none;
  }
  @media (max-width: $tablet-width) {
    .desktop-only {
      display: none;
    }
    .mobile-only {
      display: initial;
    }
    .tab-bar .tab-bar-right .p-shopping-cart {
      position: fixed;
      right: 0;
    }
  }
  @media (max-width: $mobile-width) {
    .tab-bar .tab-bar-right .p-shopping-cart {
      width: unset;
      left: 0;
    }
  }
}
</style>
