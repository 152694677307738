<template>
<div
  class="p-shopping-cart"
  :style="{ 'max-height': `${maxHeight}px` }"
  @click="$event.stopPropagation()"
>
  <h4>{{ $t('pledge.cart.title') }}</h4>
  <div v-if="hasShoppingCart" class="cart-items">
    <div
      v-for="product in shoppingCart.products"
      :key="product.id"
      class="item-wrap"
    >
      <div class="item">
        <div class="item-image" :style="{ backgroundImage: `url(${product.imageUrl})`}" />
        <div class="item-info">
          <div class="item-name subtitle">
            {{ product.name }}
          </div>
          <div v-if="product.variantName" class="item-variant subtitle">
            {{ product.variantName }}
          </div>
          <div class="item-quantity-cost">
            <h4 v-if="!product.donation" class="item-quantity">
              {{ product.quantity }}
            </h4>
            <UpDown
              v-if="!product.donation"
              :disabledDown="product.quantity <= 1"
              @up="onIncrease(product)"
              @down="onDecrease(product)"
            />
          </div>
          <div class="item-remove-cost">
            <span class="btn-remove" @click="onVariantRemove(product)">
              <span class="subheading">{{ $t('pledge.cart.remove') }}</span>
            </span>
            <h4 class="item-cost">
              {{ (product.priceUsd * product.quantity) | from_cents }}
            </h4>
          </div>
        </div>
      </div>
    </div>
    <hr class="cart-hr">
    <div class="cart-total">
      <div class="cart-total-label">
        {{ $t('pledge.cart.total') }}
      </div>
      <div class="cart-total-value">
        {{ cartSubtotalDisplay }}
      </div>
    </div>
    <button class="btn-checkout" @click="$emit('checkout')">
      {{ $t('pledge.cart.checkout_button') }}
    </button>
  </div>
  <div v-else class="no-item">
    <p>{{ $t('pledge.cart.no_item_message') }}</p>
    <button class="btn-checkout" @click="$emit('add-rewards')">
      {{ $t('pledge.cart.add_rewards') }}
    </button>
  </div>
  <button class="btn-close" @click="$emit('hide-cart')">
    {{ $t('close') }}
  </button>
</div>
</template>

<script>
import UpDown from '@/components/svg/UpDown';
import CartUpdate from '@/mixins/CartUpdate';
import { priceDisplay } from '@/utils/objectUtils';

export default {
  name: 'shopping-cart',
  components: {
    UpDown,
  },
  mixins: [CartUpdate],
  data() {
    return {
      barHeight: 0,
      windowHeight: 0,
    };
  },
  computed: {
    maxHeight() {
      return this.windowHeight - this.barHeight;
    },
    cartSubtotalDisplay() {
      return priceDisplay(this.cartSubtotal, this.project.currency);
    },
  },
  methods: {
    onResize() {
      this.windowHeight = window.innerHeight;
    },
  },
  created() {
    window.addEventListener('resize', this.onResize);
  },
  mounted() {
    this.barHeight = document.getElementById('project-tab').getBoundingClientRect().height;
    this.windowHeight = window.innerHeight;
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style lang="scss">
@import 'widgets';

.p-shopping-cart {
  width: 400px;
  max-width: 100vw;
  padding: 20px 50px;
  background: $white;
  border: 1px solid $border-light;
  line-height: normal;
  z-index: 1;
  overflow: scroll;
  .item-wrap {
    margin: 16px 0 16px;
    .item {
      display: flex;
      position: relative;
      margin-bottom: 16px;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .item-image {
    width: 64px;
    height: 64px;
    background-color: #d8d8d8;
    margin-right: 16px;
    background-size: cover;
    background-position: center;
  }
  .item-info {
    width: calc(100% - 96px);
    display: flex;
    flex-direction: column;
    .item-info-row1 {
      width: 100%;
      display: flex;
    }
    .item-name {
      font-size: 14px;
      margin-bottom: 2px;
      margin-right: 8px;
      line-height: 16px;
    }
    .item-variant {
      color: $grey;
      font-size: 14px;
    }
    .item-remove-cost {
      display: flex;
      align-items: center;
      margin-top: auto;
    }
    .item-quantity-cost {
      display: flex;
    }
    .item-quantity {
      margin: 3px 4px 0;
    }
    .item-cost {
      margin-left: auto;
    }
  }
  .btn-remove {
    fill: $grey;
    span {
      text-transform: uppercase;
      color: $main-red;
    }
  }
  .cart-hr {
    background-color: $border-med;
    margin: 0 0 16px;
  }
  .cart-total {
    display: flex;
    .cart-total-label {
      @include h4;
      color: $grey2;
    }
    .cart-total-value {
      @include h5;
      margin-left: auto;
    }
  }
  .btn-checkout {
    @include button($main-black, small, primary);
    width: 100%;
    margin: 16px 0 8px;
  }
  .btn-close {
    @include button($main-black, small, secondary);
    background-color: $white;
    text-transform: uppercase;
    width: 100%;
  }
  .no-item p {
    @include p_large;
    text-align: center;
    font-weight: 400;
    margin: 32px 0 32px;
  }
  @media (max-width: $tablet-width) {
    padding: 20px;
    width: 375px;
    max-width: 100vw;

    .item-info {
      .item-name {
        margin-right: 20px;
      }
    }
  }
}
</style>
