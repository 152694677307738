<template>
<div class="project content">
  <ProjectInfo
    v-if="project"
    :project="project"
    :projectActive="projectActive"
    @set-tab="childSetTab"
  />
  <div id="tab" />
  <ProjectTabBar
    :project="project"
    :activeTab="activeTab"
    :status="status"
    :showCart="showCart"
    :setTab="setTab"
    @hide-cart="showCart = false"
  />
  <keep-alive>
    <component
      :is="activeTab.component"
      v-if="project"
      :project="project"
      :status="status"
      @set-tab="childSetTab"
    />
  </keep-alive>
</div>
</template>

<script>
import ProjectInfo from '@/components/project/ProjectInfo';
import ProjectCampaign from '@/components/project/ProjectCampaign';
import ProjectFaq from '@/components/project/ProjectFAQ';
import ProjectUpdates from '@/components/project/ProjectUpdates';
import ProjectDiscussion from '@/components/project/ProjectDiscussion';
import ProjectTabBar from '@/components/project/ProjectTabBar';
import projectMixin from '@/mixins/Project';
import { projectStatus } from '@/utils/objectUtils';
import { getProjectPreview } from '@/utils/api';
import { ProjectStatus } from '@/utils/constants';
import { PROJECT_SET } from '@/store/actions';

export default {
  name: 'project-preview',
  components: {
    ProjectInfo,
    ProjectCampaign,
    ProjectFaq,
    ProjectDiscussion,
    ProjectUpdates,
    ProjectTabBar,
  },
  mixins: [projectMixin],
  props: {
    code: {
      type: [Number, String],
      required: true,
    },
    tab: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      activeTab: null,
      project: null,
      showCart: false,
      prevRoute: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    if(from.name) {
      next((vm) => {
        vm.prevRoute = from.fullPath;
      });
    } else {
      next();
    }
  },
  computed: {
    status() {
      return projectStatus(this.project);
    },
    projectActive() {
      return this.status === ProjectStatus.ACTIVE;
    },
    authenticated() {
      return this.$store.getters.isAuthenticated;
    },
  },
  async mounted() {
    this.project = await getProjectPreview(this.code);
    this.$store.commit(PROJECT_SET, {
      ...this.project,
      preview_code: this.code,
      prevRoute: this.prevRoute,
    });
  },
};
</script>
