<template>
<div id="project-discussion" class="container">
  <div class="discussion-wrap">
    <Comments
      v-if="project && project.id"
      :title="$t('project.discussion.title')"
      :project="project"
      :commentPermission="commentPermission"
      @go-to-pledge="goToPledge"
    />
  </div>
  <div class="discussion-info">
    <img
      class="info-icon"
      width="32"
      height="32"
      src="@/static/img/icons/ic_message.png"
    >
    <div class="description">
      <div class="label">
        {{ $t('project.discussion.info.title') | uppercase }}
      </div>
      <div class="text">
        {{ $t('project.discussion.info.text') }}
      </div>
    </div>
    <div class="description">
      <div class="label">
        {{ $t('project.discussion.info.faqTitle') | uppercase }}
      </div>
      <div class="link" @click="faqClicked">
        <span>
          {{ $t('project.discussion.info.faqLink') | uppercase }}
        </span>
        <img :src="require('@/static/img/icons/ic_contact_arrow.svg')">
      </div>
    </div>
    <div class="description">
      <div class="label">
        {{ $t('project.discussion.info.contactTitle') | uppercase }}
      </div>
      <div class="link">
        <span>
          {{ $t('project.discussion.info.contactLink') | uppercase }}
        </span>
        <img :src="require('@/static/img/icons/ic_contact_arrow.svg')">
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Comments from '@/components/widget/Comments.vue';
import { getProjectCommentPermission } from '@/utils/api';

export default {
  name: 'project-comments',
  components: {
    Comments,
  },
  props: {
    project: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      commentPermission: false,
    };
  },
  watch: {
    project: {
      immediate: true,
      handler(val, oldVal) {
        if(val && !oldVal) {
          getProjectCommentPermission({ projectId: this.project.id })
            .then((res) => {
              this.commentPermission = res.data.has_permission;
            });
        }
      },
    },
  },
  methods: {
    goToPledge() {
      this.$emit('set-tab', {
        name: 'Campaign',
        hash: '#rewards',
      });
    },
    faqClicked() {
      this.$emit('set-tab', {
        name: 'FAQ',
        hash: '#faq',
      });
    },
  },
};
</script>

<style lang="scss">
@import 'general';

#project-discussion {
  padding-top: 70px;
  padding-bottom: 70px;
  display: flex;

  .discussion-wrap {
    padding-right: 10%;
    box-sizing: border-box;
    width: 65%;
  }

  .discussion-info {
    @include subheading;
    color: $grey;
    width: 348px;
    height: 100%;
    background-color: $grey-light;
    box-sizing: border-box;
    padding: 28px 40px 48px 58px;

    .description {
      margin-top: 16px;
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
    .text {
      @include p_large;
      font-weight: 400;
    }
    .link {
      color: $main-black;
      display: inline-flex;
      align-items: center;
      font-weight: 700;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
      span {
        margin-right: 8px;
      }
      img {
        width: 24px;
        height: 10px;
      }
    }
  }

  @media (max-width: $tablet-width) {
    flex-direction: column-reverse;
    padding: 0;

    .discussion-wrap {
      width: 100%;
      margin-top: 24px;
      padding: 0 20px;
    }

    .discussion-info {
      width: 90%;
      padding: 16px 20px;
      margin: 24px auto;
      .info-icon {
        display: none;
      }
    }
  }
}
</style>

