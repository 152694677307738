<template>
<div class="flag-project">
  <Modal
    v-if="!flagConfirm"
    :submitText="$t('project.campaign.flag_project')"
    :animate="saving"
    class="flag-project-modal"
    @submit="submitFlagProject"
    @cancel="$emit('close-modal')"
  >
    <template #content>
      <h3>{{ $t('project.campaign.flag_project') }}</h3>
      <div class="flag-project-content">
        {{ $t('project.campaign.flag_project_desc') }}
      </div>
      <div class="radio-section" :class="{ error }">
        <div
          v-for="(option, name) in $t('project.campaign.flag_project_reasons')"
          :key="option.label"
          class="radio-card"
        >
          <Radio
            v-model="flagReason"
            :radioValue="name.toUpperCase()"
            @input="error = null"
          >
            <div class="radio-label" v-html="option.label" />
            <div class="radio-desc">
              {{ option.desc }}
            </div>
          </Radio>
        </div>
      </div>
      <div v-if="flagReason" class="flag-project-reason">
        <h4>
          {{ $t('project.campaign.reason.title') }}
        </h4>
        <div class="flag-project-reason-text">
          {{ $t('project.campaign.reason.desc') }}
        </div>
        <TitledInput
          v-model="flagDescription"
          :title="$t('project.campaign.reason.placeholder')"
          :suffix="flagDescription ? `${flagDescription.length}/400` : '400'"
          :maxLength="400"
          :rows="7"
        />
      </div>
      <div v-if="error" class="error-message">
        <Cross color="red" />
        {{ error }}
      </div>
    </template>
  </Modal>
  <Modal
    v-else
    :submitText="$t('project.campaign.flag_project_done')"
    class="flag-project-done"
    @submit="$emit('close-modal')"
    @cancel="$emit('close-modal')"
  >
    <template #content>
      <div class="flag-icon">
        <img :src="require('@/static/img/icons/ic_flag.png')">
      </div>
      <div>
        <h4 class="center-text">
          {{ $t('project.campaign.project_flagged') }}
        </h4>
      </div>
    </template>
  </Modal>
</div>
</template>

<script>
import Modal from '@/components/widget/Modal.vue';
import Radio from '@/components/widget/Radio.vue';
import TitledInput from '@/components/widget/TitledInput.vue';
import Cross from '@/components/svg/Cross.vue';

import { flagProject } from '@/utils/api';
import { firstErrorKey } from '@/utils/apiError';

export default {
  name: 'flag-project-modal',
  components: {
    Modal,
    Radio,
    TitledInput,
    Cross,
  },
  props: {
    projectId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      flagReason: '',
      flagDescription: '',
      flagConfirm: false,
      saving: false,
      error: null,
    };
  },
  methods: {
    async submitFlagProject() {
      this.error = null;
      if(this.flagReason) {
        this.saving = true;
        try {
          await flagProject(this.projectId, {
            reason_key: this.flagReason,
            reason_text: this.flagDescription,
          });
          this.flagConfirm = true;
        } catch(error) {
          this.error = this.$t(firstErrorKey(error.errors));
        }
        this.saving = false;

      } else {
        this.error = this.$t('project.campaign.reason.error');
      }
    },
  },
};
</script>

<style lang="scss">
@import 'general';
@import 'widgets';

.flag-project {
  .flag-project-done .modal-inner {
    h4 {
      margin-bottom: 40px;
    }
    .flag-icon {
      text-align: center;
      margin: 0 auto;
      img {
        width: 18px;
        height: 18px;
        margin-bottom: 24px;
      }
    }
  }
  .flag-project-modal .modal-inner {
    text-align: center;
    h3 {
      margin-bottom: 40px;
    }
    .p-radio label::before {
      min-width: 16px;
    }
    .flag-project-content {
      @include p_small;
      box-sizing: border-box;
      font-weight: 400;
    }
    .radio-section {
      margin: 32px 0 24px 0;
      &.error {
        border: 1px solid $main-red;
      }
      .radio-card {
        border: 1px solid $border-light;
        padding: 16px 16px 16px 0;
        label {
          align-items: flex-start;
          display: flex;
        }
        .radio-label {
          @include h5;
          display: flex;
          margin-bottom: 8px;
          a {
            margin-left: 3px;
            color: $main-blue;
            text-decoration: underline;
          }
        }
        .radio-desc {
          @include p_info_small;
          text-align: left;
        }
        &:first-child {
          border-bottom: none;
        }
      }
    }
    .error-message {
      @include p_info;
      text-align: left;
      svg {
        width: 9px;
        height: 9px;
        margin-right: 2px;
      }
    }
    .submit-button {
      margin-top: 24px;
    }
  }

  .flag-project-reason {
    border-top: 1px solid $border-light;
    padding-top: 24px;
    .flag-project-reason-text {
      padding: 16px 0 24px;
    }
  }
}
</style>
